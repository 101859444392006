import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
:root{
 --DegradeAzul: linear-gradient(90deg, #1961E0 0%, #66046B 100%);
 --DegradeAzul-hover: linear-gradient(90deg, #1961E0 10%, #66046B 90%);
 --DegradeFundo:linear-gradient(to right,rgba(233,233,233,100), rgba(248,248,248,100));
 --AzulNavBar:linear-gradient(269.74deg, #EAF1FF 6.46%, #C6D4F0 22.67%, #849FD0 37.39%, #5A7DBC 71.27%, #3863B0 100.73%);
 --a1:#4578D6;
 --a2:#3863B0;
 --a3:#2C4D8A;
 --a4:#203864;
 --a5:#14223D;
 --v1:#6FEF63;
 --v2:#89DB81;
 --v2Opaco:#89DB8175;
 --v3:#72B66B;
 --v4:#598F54;
 --v5:#41693D;
 --v125:#6FEF6350;
 --vm1:#89FFD8;
 --vm2:#7BE5C2;
 --vm3:#67C0A2;
 --vm4:#529981;
 --vm5:#3E7361;
 --vg1:#A6FEFF;
 --vg2:#9FF3F5;
 --vg3:#87CECF;
 --vg4:#6DA7A8;
 --vg5:#558182;
 --c1:#FFFFFF;
 --c2:#F0F0F0;
 --c3:#E3E3E3;
 --c4:#D6D6D6;
 --c5:#C9C9C9;
 --c6:#BBBBBB;
 --gray200:#E2E8F0;
 --c7:#606060;
 --secundario1:#C7EDEE;
 --secundario2:#A6D2FF;
 --secundario3:#CCFF66;
 --secundario4:#FFCC00;
 --secundario5:#FF9900;
 --terc1:#E18787;
 --terc2:#B265FF;
 --terc3:#FF5050;
 --terc4:#00B45A;
 --terc5:#F2D751;
 --terc6:#02EA8D;
 --terc7:#00B496;
 --terc8:#66FFFF;
 --terc9:#9BB3FF;
 --terc10:#B265FF;
 --AzulClaro2:#6DA0FF;
 --Vermelho2:#B23838;
 --Mostarda:#E5C12E;
 --MostardaOpaco:#E5C12E60;
 --Iris100:#5D5FEF;
 --Iris80:#7879F1;
 --Iris60:#A5A6F6;
 
 --Roxo:#66046B;
 --RoxoClaro:#9B51E0;
 --RoxoOpaco25:#66046B32;
 --RoxoOpaco15:#66046B15;
 --Azul:#1961E0;
 --Alaranjado: #D9B62B;
 --Alaranjado25: #D9B62B25;
 --Orange:#F2994A;
 --OrangeOpaco:#F2994A90;
 --Green1:#219653;
 --Green2:#27AE60;
 --Green2Opaco:#27AE6050;
 --Green3:#6FCF97;
 --Green3Opaco:#6FCF9725;
 --Preto:#000000;
 --AzulOpaco2:#1961E092;
 --AzulOpaco:#3984C470;
 --AzulOpaco3:#1961E040;
 --AzulOpacoBg:#3984C415;
 --Musgo:#69B89C;
 --Rosa:#CC42A2;
 --RosaOpaco:#CC42A235;
 --RosaOpacoBg:#CC42A208;
 --Gray1:#333333;
 --Gray2:#4F4F4F;
 --Gray3:#828282;
 --Gray4:#BDBDBD;
 --Gray5:#E0E0E0;
 --Gray6:#F2F2F2;
 --Gray7:#8a8a8a;
 --Blue1:#2F80ED;
 --Blue2:#2D9CDB;
 --Blue3:#56CCF2;
 --Red:#EB5757;
 --RedOpaco:#EB575730;
 --Purple1:#9B51E0;
 --Yellow5:#CCFF66;
 --Yellow:#F2C94C;
 --YellowOpaco:#F2C94C30;
 --VerdeAgua: #85CBCC;

 --a1-hover: #6e8ee9;
--a2-hover: #5c78c7;
--a3-hover: #4b629e;
--a4-hover: #405279;
--a5-hover: #32425a;
--v1-hover: #89ff7d;
--v2-hover: #9fe995;
--v3-hover: #80c982;
--v4-hover: #6c946c;
--v5-hover: #5a7f56;
--v125-hover: #8aff7d;
--vm1-hover: #a4ffd8;
--vm2-hover: #92e5c2;
--vm3-hover: #77c0a2;
--vm4-hover: #5b9981;
--vm5-hover: #476e61;
--vg1-hover: #ccfffe;
--vg2-hover: #d8f9f9;
--vg3-hover: #afeaea;
--vg4-hover: #92b2b2;
--vg5-hover: #738282;
--c1-hover: #ffffff;
--c2-hover: #f8f8f8;
--c3-hover: #ebebeb;
--c4-hover: #dfdfdf;
--c5-hover: #d1d1d1;
--c6-hover: #c3c3c3;
--gray200-hover: #f2f5f8;
--c7-hover: #909090;
--secundario1-hover: #d9f6f7;
--secundario2-hover: #b5e6ff;
--secundario3-hover: #ccff99;
--secundario4-hover: #ffd280;
--secundario5-hover: #ffbf80;
--terc1-hover: #f5aaaa;
--terc2-hover: #8f7fff;
--terc3-hover: #ff6b6b;
--terc4-hover: #00e97a;
--terc5-hover: #ffe788;
--terc6-hover: #43f5a8;
--terc7-hover: #00e97d;
--terc8-hover: #80ffff;
--terc9-hover: #acc4ff;
--terc10-hover: #8f7fff;
--AzulClaro2-hover: #86b4ff;
--Vermelho2-hover: #b25656;
--Mostarda-hover: #ffe24d;
--MostardaOpaco-hover: #ffe24d99;
--Iris100-hover: #6b6dff;
--Iris80-hover: #8081ff;
--Iris60-hover: #a6a7ff;
--Roxo-hover: #990e8f;
--RoxoClaro-hover: #c662ee;
--RoxoOpaco25-hover: #990e8f40;
--RoxoOpaco15-hover: #990e8f24;
--Azul-hover: #1e7ee0;
--Alaranjado-hover: #e2ab1f;
--Alaranjado25-hover: #e2ab1f40;
--Orange-hover: #f7b97a;
--OrangeOpaco-hover: #f7b97a90;
--Green1-hover: #29ae59;
--Green2-hover: #3cc569;
--Green2Opaco-hover: #3cc56950;
--Green3-hover: #82e3b1;
--Green3Opaco-hover: #82e3b165;
--Preto-hover: #282828;
--AzulOpaco2-hover: #1e7ee092;
--AzulOpaco-hover: #478bb370;
--Musgo-hover: #80a792;
--Rosa-hover: #de66b9;
--Gray1-hover: #4d4d4d;
--Gray2-hover: #717171;
--Gray3-hover: #a6a6a6;
--Gray4-hover: #cccccc;
--Gray5-hover: #e6e6e6;
--Gray6-hover: #f7f7f7;
--Blue1-hover: #459aff;
--Blue2-hover: #46afff;
--Blue3-hover: #74dfff;
--Red-hover: #f78282;
--Purple1-hover: #ae76ff;
--Yellow5-hover: #f0ff99;
--Yellow-hover: #ffdc70;
--VerdeAgua-hover: #99eeef;

--smFontBtn:.75rem;
--mdFontBtn:1rem;
--lgFontBtn:1.25rem;

--smIconSize:3.125rem;
--mdIconSize:3.844rem;
--lgIconSize:4.25rem;

--smPaddingBtn:0rem 1.5rem 0rem 1.5rem;
--mdPaddingBtn:0rem 2rem 0rem 2rem;
--lgPaddingBtn:0rem 2rem 0rem 2rem;

--smHeigthBtn:2.375rem;
--mdHeigthBtn:2.688rem;
--lgHeigthBtn:2.938rem;

--smLabel:1rem;
--mdLabel:1.25rem;
--lgLabel:1.5rem;

 --RadiusModal:0.625rem;
 --transitionBtns:0.1s;
 --SombraBtns:0rem 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
 --SombraBackground:0rem 0.125rem .5rem rgba(0, 0, 0, 0.08);
 --SombraMenuDropdown:-1px 3px 6px rgba(0, 0, 0, 0.25);
}

*{
  margin: 0;
  padding: 0;
  box-sizing:border-box;
}

html{
  @media(max-width: 1080px){
    font-size:93.75%;
  }

  @media(max-width: 720px){
    font-size:87.5%;
  }
}

.IconLeft{
  margin-right:.75rem;
}

.IconRigth{
  margin-left:.75rem;
}

.react-modal-overlay{
  background-color: rgba(0, 0, 0, 0.5);

  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  overflow-y: auto;

  width: 100vw;
  height: 100vh;
  ::-webkit-scrollbar {
    width: 0rem;
  }
}

.react-modal-content-poupUp{
  width:37rem;
  max-height: 350px;
  background: white;
  padding: 1rem;
  position: relative;
  border-radius:.25rem;
  box-shadow: var(--SombraBackground);
  outline: 0;

  div:nth-child(1){
   justify-content: end;
  }

  div:nth-child(2){
    justify-content: space-between;
    height: 90%;
    align-items: center;
  }

  span{ 
    font-weight: 700;
    font-size: 20px;
  }

}

.react-modal-content-default{
  background: white;
  position: absolute;
  border-radius:.25rem;
  box-shadow: var(--SombraBackground);
  outline: 0;
}

.react-modal-content-scroll{
  background: white;

  overflow-y: auto;
  position: relative;
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 2rem);

  ::-webkit-scrollbar {
    width: 0rem;
  }

  border-radius:.25rem;
  box-shadow: var(--SombraBackground);
  outline: 0;
}

.react-modal-content-styled-scroll{
  outline: 0;
  background: var(--c1);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  max-width: 100vw;
  max-height: 95vh;

  ::-webkit-scrollbar {
  width: .5rem;
}
/* heigth */
::-webkit-scrollbar {
  height: 0.375rem;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.625rem;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
}

a{
  text-decoration: inherit;
  color: inherit;
}

h1{
font-family: 'Poppins','sans-serif';
line-height: 2.15rem;
font-weight: 700;
font-size: 1.75rem;
}

h2{
  font-family: 'poppins', sans-serif;
  font-weight:600;
  font-size: 1.375rem;
  line-height: 1.625rem;
}

h3{
  font-size:1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
}

h4{
  font-size:.925rem;
  line-height: 1.125rem;
  font-weight: 500;
}

//lineheigth sempre meio rem a mais que o font size

body{
    background: var(--DegradeFundo)
}

label,input[type=text],a,button,h3,h4,span,textarea,p{
  font-family: 'Roboto','sans-serif';
}

input:focus {
  outline: none !important;
  border: none;
}

textarea {
  resize: vertical;
}

span{
  line-height: 115%;
}

button,a{
  cursor: pointer;

}

button {
border:none;
color: var(--c1);
}

[disabled]{
  opacity: 0.6;
  cursor:not-allowed ;
}

.disabled{
  opacity: 0.6;
  pointer-events: none;
}

.p2{
  font-weight: 600;
  color: var(--a3);
  font-size: 1rem;
}
.axis-vertical{
  margin: 0 !important;
}
.dot{
  background: var(--DegradeAzul) !important;
  box-shadow: none !important;
}
.carousel-status {
  display: none;
}
.control-dots{
  bottom: -.325rem !important;
}
.slide{
  padding: 0rem 0 !important;
}
.control-arrow{
  background: var(--Green2) !important;
  opacity: .3 !important;
  :hover{
    opacity: 1 !important;
  }
}
.carousel-root{
  border: 1px solid var(--c5) !important;
  border-radius: .25rem !important;
  max-width: 70%;
}
.hide, .hideImersao, .hideImersaoPDF {
  display: none !important;
}
@media (min-width: 1025px) and (max-width: 3000px) {
  .hideImersao {
    display: flex !important;
  }
  .hideImersaoPDF {
    display: block !important;
  }
}
@media (min-width: 0px) and (max-width: 1024px) {
.hide {
  display: flex !important;
}

`
